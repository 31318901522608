import React, { useEffect, useState } from 'react'
import './BaseSet.css';
import Heading from '../../components/Heading/Heading';
import Text from '../../components/Text/index';
import Ncard from '../../components/Card/Ncard';
import ben from './ben.png'
import backimage from './baseset.png';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Row, Col, Container, Button } from "reactstrap"
import Large from '../LargeCard/Large';
import { useSelector } from 'react-redux';
import { data } from '../config/config';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#modal');




const BaseSet = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState("");
  const [saleData, setsaleData] = useState("");
  const mydata = useSelector(state => state.mydata);


  useEffect(() => {
    console.log("hiii", mydata)
    let array = []
    data.map((e) => e.main && array.push(e))    // 
    setsaleData(array)
    return () => {

    }
  }, [])

  function openModal() {
    setIsOpen(true);
  }

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    768: { items: 2 },
    900: { items: 2 },
    1000: { items: 3},
    1024: { items: 3 },

  };

  function closeModal() {
    setIsOpen("");
  }

  return (

    <section >
      <div style={{
        backgroundImage: `url('${backimage}')`,
        backgroundRepeat: "no-repeat", backgroundPosition: "bottom",
        backgroundSize: "100% 50%", minHeight: "100vh",
        background: "#663399",
        padding: 20,
        // minWidth: "100vw"
        display: 'grid',
        placeItems: 'center',
      }}>
        <div>
        <div style={{ textAlign: "center" }}>
          <h2>Base Set</h2>
          <div className="PBB">
            <Text Text="Polymon exist in many shapes and forms, each differing in unique looks and "></Text>
            <Text Text="rarity. Ranging from the most common, to the rarest!"></Text>
          </div>
          {/* <div className="PKMKB"><Text Text="Polymon exist in many shapes and forms, each differing in unique looks and rarity. Ranging from the most common, to the rarest!" /> </div> */}
        </div>
        <AliceCarousel
          mouseTracking
          // items={items}
          responsive={responsive}
          keyboardNavigation={true}
          // controlsStrategy="alternate"
        >

          {saleData && saleData?.map(e => {
            if (e.main) {
              return(
                <Ncard link={e.img} width="100%" padding="0" name={e.name.toUpperCase()} onBuy={() => {
                  setIsOpen(e)
                  
                }} />
                // <Dcard link={e.img} name={e.name} details={e.desc} />
              )
            }else{
              <div></div>
            }
          })}
        </AliceCarousel>
        {/* <div style={{display:"flex", flexFlow:"row wrap",textAlign:"center", width:"100%",alignItems:"center", justifyContent:"center"}}>
          <div style={{maxWidth:"330px", width:"100%"}}>
            <Ncard link={ben} width="100%" padding="0" name="Rover" onBuy={() => {
              setIsOpen(true)
            }} />
          </div>
          <div style={{maxWidth:"330px", width:"100%"}}>
            <Ncard link={ben} width="100%" padding="0" name="Rover" onBuy={() => {
              setIsOpen(true)
            }} />
          </div>
          <div style={{maxWidth:"330px", width:"100%"}}>
            <Ncard link={ben} width="100%" padding="0" name="Rover" onBuy={() => {
              setIsOpen(true)
            }} />
          </div>
        </div> */}


        {/* <div >
          <Ncard link={ben} width="100%" padding="0" name="Rover" onBuy={() => {
            setIsOpen({img:ben,name:"Ance",desc:"Ance created by BSC. A man bred PolyMon.", details:"Ance is the legendary lightning bird Polymon. It is also one of the few legendary Polymon – and Polymon in general – that does not have a nest of its own, or even a general area where it lives.Instead of having a permanent place, Ance simply follows storms. It as a supernatural instinct that allows it to know where in the world thunderstorms are currently ongoing, and exactly what direction they are heading. Ance is always traveling to the nearest thunderstorm. Once inside it, it flies among the clouds, absorbing the electrical energy of the storm and replenishing its powers.",rank:"08"})
          }} />
        </div> */}

        {modalIsOpen && <Large setOpen={() => setIsOpen("")} data={modalIsOpen} />}
        </div>
        
          {/* <Row>
            <Col xs="6">
              <img src={ben} style={{}} />

            </Col>

            <Col xs="6">


              <div>
                <h2>BITIC</h2>
                <span>
                  PolyMon is a platform that combines trading, collecting and gaming with blockchain technology. We are trying to aim for an ecosystem where users can collect, earn and trade NFT’s by farming and eventually playing the game.
                </span>

              </div>



              <Row style={{ marginTop: 10 }}>
                <img src={"/images/matic.png"} style={{
                  height: 50, width: 50,
                  marginRight: 10,
                  verticalAlign: "middle"
                }} />
                <h2>100 Matic</h2>
              </Row>


              <Button style={{
                background: "#1E2947", paddingLeft: 40,
                borderRadius: 30,
                fontSize: 20,
                paddingTop: 10,
                paddingBottom: 10,
                paddingRight: 40
              }}>
                Buy Now
              </Button>
            </Col>
          </Row>



*/}

      </div> 
    </section>

  )
}

export default BaseSet
