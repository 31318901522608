import React, { useContext } from 'react'
import './Roadmap.css';
import Rocard from '../../components/Card/Rocard';
// import { ItemContext } from '../config/Provider';
import Heading from '../../components/Heading/Heading';
import Image from '../../components/Image/Image'
import styled from 'styled-components';
import Text from '../../components/Text';
import monkey from './monkey.png';
import cat from './cat.png';
import turtle from './tutle.png';
import {data} from  '../config/config'

const Left = styled.div`
    width:${window.screen.width > 760 ? "50%" : "100%"} ;
    height:${window.screen.width > 760 ? "100%" : ""} ;
    padding: 1rem;

    `;

const Right = styled.div`
    width: ${window.screen.width > 760 ? "50%" : "100%"};
    margin-bottom:2px;
`;


const Roadmap = () => {

    // const item = useContext(ItemContext)

    return (
        
        <section className="road">
            <div style={{ marginTop: "5rem" }}>
                <Heading size="3" Text="ROADMAP"></Heading>
            </div>
      
            <div style={{ marginTop: "2rem" }}>

                <div className="one" style={{zIndex:2, boxShadow:"0px 20px 12px 0px rgba(0,0,0,0.25)",maxHeight:`${window.screen.width > 900 ? "16rem":"50rem"}`}}>
                    <Left className="LL"><Image link={cat} /></Left>
                    <Right style={{paddingBottom:"5px"}}>
                        <Heading color="#1E2947" Text="ALPHA" />
                        <Text color="#1E2947" Text="Website launch" />
                        <Text color="#1E2947" Text="Token Private Sale" />
                        <Text color="#1E2947" Text="Token Public Sale" />
                        <Text color="#1E2947" Text="Strategic Partnerships" />
                        {/* <Text color="#1E2947" Text="Website launch" /> */}
                    </Right>
                </div>

                <div className="two" style={{zIndex:1, boxShadow:"0px 20px 12px 0px rgba(0,0,0,0.25)",position:"relative",maxHeight:`${window.screen.width > 900 ? "16rem":""}`}}>
                    <Left><Image link={turtle} /></Left>
                    <Right style={{paddingBottom:"5px"}}>
                        <Heading color="#1E2947" Text="BETA" />
                        <Text color="#1E2947" Text="NFT Utility" />
                        <Text color="#1E2947" Text="NFT Farms" />
                    </Right>
                </div>

                <div className="three" style={{zIndex:0, boxShadow:"0px 20px 12px 0px rgba(0,0,0,0.25)",position:"relative", maxHeight:`${window.screen.width > 900 ? "16rem":""}`}}>
                    <Left><Image link={monkey} /></Left>
                    <Right style={{paddingBottom:"5px"}}>
                        <Heading color="#1E2947" Text="GAMMA" />
                        <Text color="#1E2947" Text="NFT Gaming"  />
                    </Right>
                </div>

            </div>
        </section>
        
    )
}

export default Roadmap
