import React from 'react'
import './Large.css'
import Bcard from '../../components/Card/Bcard'

const Large = (props) => {
    console.log(props,"props")
    return (
        <div className="sec">
            <Bcard details={props?.data?.details || ""} link={`images/${props?.data?.name.toUpperCase()||""}.png`} title={props.data.name.toUpperCase()} setOpen={props.setOpen} data={props.data}  />
        </div>
    )
}

export default Large
