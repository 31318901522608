import React,{useContext} from 'react'
import './Polyverse.css';
import Heading from '../../components/Heading/Heading';
import Text from '../../components/Text/index'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { ItemContext } from '../config/Provider';
import {data} from '../config/config'
import Dcard from '../../components/Card/Dcard';
import styled from 'styled-components';

// const handleDragStart = (e) => e.preventDefault();

// const items = [
//   <img src={poly} onDragStart={handleDragStart} />,
//   <img src={poly} onDragStart={handleDragStart} />,
//   <img src={poly} onDragStart={handleDragStart} />,
// ];

// const Grid = styled.div`
//     min-height: 100vh;
//     width: 100%;
//     display: grid;
//     place-items: center;
// `;

const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    768: { items: 1 },
    900: { items: 3 },
    1000: { items: 3 },
    1024: { items: 3 },

};



// const items = [
//     <img src={poly} />,
//     <div className="item" data-value="2">2</div>,
//     <div className="item" data-value="3">3</div>,
//     <div className="item" data-value="4">4</div>,
//     <div className="item" data-value="5">5</div>,
// ];


const Polyverse = ()=>{

    // const item = useContext(ItemContext)

    return (
       
        <section className="poly">
            <div style={{marginTop:"40px",textAlign:"center", marginBottom:"20px"}}>
                {/* <Heading color="#1E2947" Text="DISCOVER THE POLYVERSE!" ></Heading> */}
                <h2 style={{color:"#8648e5"}}>
                DISCOVER THE POLYVERSE!
                </h2>
                <div style={{paddingTop:"1rem"}} className="TVS"><Text color="#6a76a2" Text="Polymon exist in many shapes and forms, each differing in unique looks and rarity. Ranging from the most common, to the rarest"></Text></div>
            <div className="HONDA"><Text color="#6a76a2" Text="Polymon exist in many shapes and forms, each differing in unique looks and rarity. Ranging from the most common, to the rarest"></Text></div>
            </div>
            {/* <br /> */}
            
            <AliceCarousel 
            mouseTracking
            // items={items}
            responsive={responsive}
            keyboardNavigation={true}
            // controlsStrategy="alternate"
            >
                
                { data?.map( e => (
                    <Dcard link={e.img} name={e.name} details={e.desc} />
                 ))}
                
           </AliceCarousel>
           
        </section>
      
    )
}

export default Polyverse

