import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "../../components/Image/Image";
import Text from "../Text/index";
import Heading from "../Heading/Heading";
import matic from "../../components/Card/matic.png";
import Button from "../../components/Button/Button";
import cross from "./cross.png";
import { FaWindowClose } from "react-icons/fa";
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from "react-redux";
import { data } from "../../views/config/config";
import { useWallet } from 'use-wallet'
import config from "../BlockchainProvider/config";
import Web3 from "web3";

const Bcard = (props) => {

  const wallet = useWallet()
  const contract = useSelector((state) => state.contract);
  // const [maxSupply, setmaxSupply] = useState();
  const [percentage, setpercentage] = useState(0);

  const [approval,setApproval] = useState()
  const [address,setAddress ] = useState();
  const mydata = useSelector((state) => state.mydata);

  const [isLoading,setLoading] = useState()
  const mintNFT = async () => {

    console.log("dsdsdsdssd",contract,address)
    if (mydata && mydata.contracts && address) {
      try{
        await mydata.contracts.nftContract.methods.mint(0).send({
            from: address,
          });
      }catch(err){
          console.error(err)
      }
    } else {
      alert("Contract Not Intilized");
    }
  };



  useEffect(()=>{
    if(wallet.account){
        setAddress(wallet.account)
       
    }
  },[wallet.account])

  useEffect(()=>{
      if(mydata&& mydata.contracts && address){
        loadTokenData()

      }
  },[mydata,address])


  const loadTokenData = async ()=>{
    let contract =  mydata.contracts.tokenContract;

    console.log("sqaasasasasaaas",contract.methods,address,config.CONTRACT_ADDRESS)
    let allowance = await contract.methods.allowance(address,config.CONTRACT_ADDRESS).call();
    setApproval(allowance);
  }

  const getSupply = () => {
    console.log(mydata, "data");
    const max = mydata?.type1Max;
    const total = mydata?.type1Minted;


    const percent = (total / max) * 100;
    console.log(total, max, percent, "perce");
    setpercentage(percent);
  };

  useEffect(() => {
    getSupply();
    return () => {};
  }, []);



  const handleApproval =async ()=>{
    setLoading(true)
    try{
        await mydata.contracts.tokenContract.methods.approve(config.CONTRACT_ADDRESS,Web3.utils.toWei("100000000000000000000")).send({from:address})
        loadTokenData();
    }catch(rr){
        console.log("errrer",rr)
    }
    setLoading(false)
  }

  const renderButton = ()=>{
      if(address){
          if(parseInt(approval)>0){
            return    <Button
            isLoading={isLoading}
            onClick={() => mintNFT()}
            round
            Text={`${props.btn ? props.btn : "BUY NOW"}`}
            bg="#1E2947"
            width="15rem"
            height="4rem"
          ></Button>
          }else{
            return    <Button
            isLoading={isLoading}
            onClick={() => handleApproval()}
            round
            Text={"Approve Contract"}
            bg="#1E2947"
            width="15rem"
            height="4rem"
          ></Button>
          }
     
      }else{
        return    <Button
        isLoading={isLoading}

        onClick={() => wallet.connect()}
        round
        Text={"Connect Wallet"}
        bg="#1E2947"
        width="15rem"
        height="4rem"
      ></Button>
      }
  }
  const Box = styled.div`
    flex-direction: column;
    // height: 65rem;
    width: 90%;
    background: #8648e5;
    border-radius: 8px;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 10px;
    ${window.screen.width < 768} {
      max-height: 40rem;
      max-width: 100rem;
      background: #8648e5;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 8px;
      flex-direction: row;
    }
  `;

  const Left = styled.div`
    ${window.screen.width < 768} {
      width: 35%;
      height: 90%;
    }
    width: 100%;
    height: 34%;
  `;
  const Right = styled.div`
    ${window.screen.width < 768} {
      width: 50%;
      height: 90%;
      // background: red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `;
  const Divider = styled.div`
    height: 0.5px;
    width: 100%;
    background: white;
  `;

  return (
    <Box
    // style={{paddingBottom:`${window.screen.width < 900 ? "110px" :""}`}}
    >
      <FaWindowClose
        onClick={() => {
          props.setOpen();
        }}
        style={{
          fontSize: "2rem",
          cursor: "pointer",
          display: `${window.screen.width < 768 ? "block" : "none"}`,
        }}
      />

      <Left>
        <Image
          style={{
            width: `${window.screen.width < 900 ? "150px" : ""}`,
            height: `${window.screen.width < 900 ? "150px" : ""}`,
          }}
          link={`${
            props.link
              ? props.link
              : "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg"
          }`}
        />
      </Left>
      <Right>
        <Heading Text={`${props.title ? props.title : "BITIC"}`} size="3" />
        {window.screen.width > 900 && (
          <Text
            color="white"
            size="1"
            Text={`${
              props.details
                ? props.details
                : "PolyMon is a platform that combines trading, collecting and gaming with blockchain technology. We are trying to aim for an ecosystem where users can collect, earn and trade NFT’s by farming and eventually playing the game."
            }`}
          ></Text>
        )}
        <div style={{ width: "100%" }}>
          <ProgressBar
            completed={percentage}
            baseBgColor="#BA8CFF"
            bgColor="#FFFFFF"
            labelColor="#1E2947"
            width={`${window.screen.width > 768 ? "60%" : "100%"}`}
          />
        </div>
        <div style={{ display: "flex", flexFlow: "row wrap" }}>
          <Text Text={"Total Minted : " + mydata?.type1Minted}></Text>
          <Text Text="&nbsp;&nbsp;&nbsp;"></Text>
          <Text Text={"Max Supply : " + mydata?.type1Max}></Text>
        </div>
        {/* <Divider /> */}
        <div
          style={{ display: "flex", marginTop: "20px", alignItems: "center" }}
        >
          <img src={matic} alt="m" style={{ width: "3rem", height: "3rem" }} />
          {mydata && mydata.price?<Heading Text={`${mydata.price} USDC`} size="2" />:null}
          
        </div>
        {/* <Divider /> */}
        <div style={{ marginTop: "20px" }}>
            {renderButton()}
        </div>
      </Right>
      {/* <img src={cross} alt="cross" style={{cursor:"pointer"}}/> */}
      <FaWindowClose
        onClick={() => {
          props.setOpen();
        }}
        style={{
          marginBottom: "30rem",
          fontSize: "2rem",
          cursor: "pointer",
          display: `${window.screen.width > 768 ? "block" : "none"}`,
        }}
      />
    </Box>
  );
};

export default Bcard;
