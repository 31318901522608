import React from 'react'
import './Open.css';
import Heading from '../../components/Heading/Heading'
import Text from '../../components/Text/index'
import Button from '../../components/Button/Button'
import center from './center.png';
import left from './left.png'
import bottom from './bottom.png';

const Open = () => {
    return (
        
        <section className="opn" id="O">
            <div className="anam">
            {/* <img src={left} alt="" style={{position:"",left:"0",top:"0"}} />
            <img src={bottom} alt="" style={{position:"relative",left:"0",bottom:"0"}}/> */}
            <div className="Left">
                <Heading Text="COLLECT, TRADE AND" size={`${window.screen.width > 768 ? "4":"2"}`} />
                <Heading Text="PLAY YOUR POLYMON" size={`${window.screen.width > 768 ? "4":"2"}`}/>
                <Button round Text="BUY BOOSTER PACK" width="15rem" height="4rem"/>
            </div>
            <div className="Right">
                <img src={center} alt="" style={{width:`${window.screen.width > 760 ? "" : "90%"}`}} />
            </div>
            </div>
        </section>
      
    )
}

export default Open
