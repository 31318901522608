import React from 'react'
import styled from 'styled-components';
import Image from '../Image/Image';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Text from '../Text';
import matic from './matic.png'
import { useSelector } from "react-redux";

const Ncard = (props) => {


    const mydata = useSelector((state) => state.mydata);

    const Box= styled.div`
        // height: 30rem; 
        max-width: 20rem;   
        // background:  : white;  
        border-radius: 20px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // padding: 14px;
        display:block;
        margin: 2rem 0.5rem 0 0.5rem;
    `;
    const Upper = styled.div`
    height: 50%;
    width: 100%;
    // background:linear-gradient(223.06deg, #F6B97B -12.52%, #8648E5 109.87%)
    background: linear-gradient(0deg, rgba(143,82,219,1) 0%, rgba(190,128,177,1) 98%, rgba(191,128,175,1) 100%, rgba(0,0,0,1) 100%); 
    border-radius: 8px;`;
    const Lower = styled.div`
        width: 100%;
        height: 25%;
        display:flex;
        padding:14px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        //  background: black;
        //  margin-top: 1rem;
        `;

    return (
        <Box >
            <Upper>
                <Image 
                // width={18}
                // height={28}
                link={`${props.link ? props.link : ""}`} />
            </Upper>
            <Lower>
                <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
                    <div style={{textAlign:"left"}} >
                    <Heading Text={`${props.name ? props.name : "Vivek"}`} color="black" size="2" />
                    <div style={{display:"flex",alignItems:"center",marginBottom:"0.3rem",}}>
                    <img src={matic} alt="m" style={{width:"1rem"}} />
                    <div style={{marginLeft:"0.4rem"}}>
                        <span Text="100 USDC" style={{color:"#8648E5",fontWeight:"bold"}} >
                        {mydata &&mydata.price?mydata.price:""} USDC</span></div>
                    </div>
                    </div>
                    <div style={{marginTop:"0.5rem"}}><Button round Text={`${props.sbtn? props.sbtn : "AVAILABLE"}`} height="1.8rem" width="8rem" bg="#BCA657"/></div>
                </div>
                <Button onClick={()=>{
                    props.onBuy()
                }} Text={`${props.btn? props.btn : "BUY NOW"}`} width="100%" height="2.5rem"/>
            </Lower>
        </Box>
    )
}

export default Ncard
