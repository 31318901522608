import React from 'react'

const Text = (props) => {
    return(
        <p style={{fontSize:`${ props.size? props.size+"rem" :"1.1rem"}`, 
        color:`${props.color? props.color : "white"}`,
        fontFamily: "'Work Sans', sans-serif"
        }}>
          {props?.Text}
        </p>
    )
}

export default Text;




