import React from 'react'
import styled from 'styled-components'

const Button = (props) => {
    const Btn = styled.button`
    color: ${ props.color ? props.color : "white" };
    background: ${ props.bg ? props.bg : "#8D4FEC" };
    border-radius: ${ props.round ? "2rem" : "10px"};
    padding-left:10px;
    padding-right:10px;
    // padding-top:10px;
    // padding-bottom:10px;

    border: 1.5px solid ${props.bg ? props.bg : "#8D4FEC"};
    cursor: pointer;
    height: ${ props.height? props.height : "3rem"};
    min-width: ${ props.width? props.width : "9rem"};
    // letter-spacing: 3px;
    font-weight: 400;

    font-size: 1rem;
`

    return (
        <Btn type="button" onClick={()=>{
            if(props.onClick){
                props.onClick()
            }
        }}>
            {props.isLoading?<div class="spinner-border text-secondary" role="status">
  <span class="sr-only">Loading...</span>
</div>:props.Text? props.Text : "Default Button"}
          

        </Btn>
    )
    }
export default Button;