import React from 'react'
import './Home.css';
import Heading from '../../components/Heading/Heading';
import Text from '../../components/Text/index';
import Button from '../../components/Button/Button';
import BaseSet from '../BaseSet/BaseSet';
import WhatIsPoly from '../WhatIsPoly/WhatIsPoly';
import Polyverse from '../Ployverse/Polsyverse';
import Roadmap from '../Roadmap/Roadmap';
import Supply from '../Supply/Supply';
import Last from '../Last/Last';
import styled from 'styled-components';

const HomeStyle = styled.div`
    
`;

const Home = () => {

    return (
        <HomeStyle style={{overflow:"hidden"}}>
         
            <div className="mainBg"> 
            <section className="Home" id="H" >
                <div className="PHH" style={{textAlign:"center"}}>
                    <h2 size="1"
                    >
                        COLLECT, TRADE AND PLAY <br/> YOUR POLYMON
                    </h2>
{/* 
                    <span style={{fontWeight: 500}}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod <br/>
                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                    
                    </span> */}

              
                </div>
             
              
                <div style={{ marginTop: "2rem" }}>
                    <a href="/store"><Button font in web pageon round Text="DISCOVER" height="4rem" width="12rem"></Button></a>
                </div>

            </section>
                      

            <BaseSet />

            </div>
            {/* <WhatIsPoly /> */}
            <Polyverse/>
            <Roadmap />
            <Supply />
            <Last />
        </HomeStyle>
    )
}


export default Home
