import React from 'react'

const Heading = (props) => {
    return (
        <h1 style={{
            
            fontSize:`${props.size ? props.size+"rem" : "1rem"}`,  
            fontFamily:"'Oswald'",
            color:`${props.color? props.color : "white"}`
            
            }}> 
          { props.Text }
        </h1>
    )
}

export default Heading
