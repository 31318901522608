import React from 'react'
import './Footer.css'
import Text from '../../components/Text/index';
import logo from './logo.png'
import Image from '../../components/Image/Image'
import twitter from './twitter.png'
import discord from './discord.png'
import m from './m.png'

const Footer = () => {
    return (
        <div className="last">
            <Text Text="© 2021 POLYMON. ALL RIGHTS RESERVED."></Text>
            <div style={{width:"10rem"}} className="sh">
                <Image link={logo} />
            </div>
            <div className="icon">
               <a href="https://twitter.com/polymonofficial" target="_blank"><div style={{width:"2rem"}}><Image link={twitter}/></div></a> 
               <a href="https://t.me/polymonofficial" target="_blank"><div style={{width:"2rem"}}><Image link={discord}/></div></a> 
               <a href="https://medium.com/@Polymonofficial" target="_blank"> <div style={{width:"2rem"}}><Image link={m}/></div></a>
            </div>
        </div>
    )
}

export default Footer
