import React from 'react'
import './App.css';
import NavBar from './views/NavBar/NavBar';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import Home from './views/Home/Home';
import BaseSet from './views/BaseSet/BaseSet';
import Polyverse from './views/Ployverse/Polsyverse';
import Roadmap from './views/Roadmap/Roadmap'
import Supply from './views/Supply/Supply';
import Last from './views/Last/Last'
import Polymon from './views/Polymon/Polymon';
import Store from './views/Store/Store';
import Open from './views/Open/Open'
import Large from './views/LargeCard/Large';
import Post from './views/Post/Post';
import WhatIsPoly from './views/WhatIsPoly/WhatIsPoly';
import Footer from './views/Footer/Footer';
import { useWallet, UseWalletProvider } from 'use-wallet'
import BlockchainProvider from './components/BlockchainProvider';

const App = () => {
  return (
    <>

<UseWalletProvider
    chainId={97}
    connectors={{
      // This is how connectors get configured
    }}
  >
    <BlockchainProvider />
    <>
    <Router>
        <NavBar />
        <Switch>

          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/polymon" exact>
            <Polymon />
            {/* <Post /> */}
          </Route>
          <Route path="/openpack" exact>
            <Open />
          </Route>
          <Route path="/store" exact>
            <Store />
          </Route>
          <Route >
            <div style={{ minWidth: "100vw", minHeight: "100vh", textAlign: "center", paddingTop: "200px", fontSize: "2rem", color: "white" }}>
              404 not found!!
            </div>
          </Route>
        </Switch>
      </Router>
      <Footer />
      
    </>
  </UseWalletProvider>



    </>
  )
}

export default App

