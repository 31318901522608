import React from 'react'
import './Last.css'
import Text from '../../components/Text'
import Heading from '../../components/Heading/Heading'
import Button from '../../components/Button/Button'

const Lcard = (props) => {
    return (
      <div className="box">
                {window.screen.width > 900 && <Text size={`${window.screen.width > 768 ? "1.8" : "1.5"}`} Text={`${props.title? props.title : "Do you want to get updated about the upcoming Public Sale?"}`} ></Text>}
                <div>
                <Heading size={`${window.screen.width > 768 ? "2.3" : "1.8"}`} Text={`${props.heading? props.heading : "SIGN UP! YOU CAN UNSUBSCRIBE ANYTIME."}`} ></Heading>
                </div>
                <div className="ib">
                <input placeholder="Enter your email address" type="email" className="in" style={{paddingLeft:"1.5rem",fontSize:"1rem"}}/>
                <div className="btn">
                <Button round Text="SEND" bg="#1E2947" />
                </div>
                </div>
            </div>
    )
}

export default Lcard
