import React from 'react'
import styled from 'styled-components'

const Image = (props) => {
   
    const Img = styled.img`
        height: ${ props.height? props.height+"rem" : "100%"};
        width: ${ props.width? props.width+"rem" : "100%"};
        // background: black;
        border-radius: 12px;
 `;

    return (
        <Img src={`${props.link}`} >
            
        </Img>
    )
}

export default Image
