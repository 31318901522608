import React from 'react'
import './Last.css'
import Text from '../../components/Text'
import Heading from '../../components/Heading/Heading'
import Button from '../../components/Button/Button'
import Lcard from './Lcard'
import Footer from '../Footer/Footer'

const Last = () => {
    return (
        <>
        <section className="dev">
            {/* <div className="box">
                <Text Text="Do you want to get updated about the upcoming Public Sale?" size="1.8"></Text>
                <Heading Text="SIGN UP! YOU CAN UNSUBSCRIBE ANYTIME." size="2.8"></Heading>
                <div className="ib">
                <input placeholder="Enter your email address" type="email" className="in" />
                <div className="btn">
                <Button round Text="SEND" bg="#1E2947" />
                </div>
                </div>
            </div> */}
            <Lcard />
            
        </section>
        {/* <Footer /> */}
        </>
    )
}

export default Last
