import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import simpleStorageAbi from './ABI.json';
import ERC20ABI from './ERC20.json';

import Config from './config'
import { accountUpdate, contractLoaded, dataLoaded, ethtodollar } from '../../redux/actions';
import { connect, useSelector } from 'react-redux';
import config from './config';
import { useWallet } from 'use-wallet';


function BlockchainProvider(props) {

    
    const [contract, setcontract] = useState()

    const wallet = useWallet()
    useEffect(() => {
        
        loadContractData()

    }, [wallet.ethereum])


    
    const loadContractData = ()=>{
        console.log(props.eth, "hii")
        //const contractAddr = Config.CONTRACT_ADDRESS
        let provider = Web3.givenProvider;
        if(!provider){
            provider =  new Web3.providers.HttpProvider(config.RPC);
        }
        const web3 = new Web3(provider);
        try {
            // account(web3)
            const SimpleContract = new web3.eth.Contract(simpleStorageAbi, Config.CONTRACT_ADDRESS);
            props.dispatch(contractLoaded(SimpleContract))
            console.log(SimpleContract, "contract")
            setcontract(SimpleContract)
            props.dispatch(ethtodollar(props.eth))
            setInterval(() => {
                console.log("hi")
                initdata(web3,SimpleContract)
            }, 1000);
        }
        catch (err) {
            console.log(err)
        }
    }
    // async function account(web3) {
    //     const accounts = await web3.eth.getAccounts()

    //     //console.log(accounts[0],"accounts")
    //     props.dispatch(accountUpdate(accounts[0]))

    // }




    async function initdata(web3,contract) {
       
            let type1Minted = await contract.methods.type1Minted().call()
            let type2Minted = await contract.methods.type2Minted().call()
            let type3Minted = await contract.methods.type3Minted().call()


            let type1Max = await contract.methods.type1Max().call()
            let type2Max = await contract.methods.type2Max().call()
            let type3Max = await contract.methods.type3Max().call()
            let price = await contract.methods.pricePerNft().call();

            let tokenContract = new web3.eth.Contract(ERC20ABI,config.PAYING_TOKEN);



            price = price/1e18
            let data = {
                type1Minted,
                type2Minted,
                type3Minted,
                type1Max,
                type2Max,
                type3Max,
                price,
                contracts:{
                    nftContract:contract,
                    tokenContract
                }
            }

            // console.log(data, "fetched Data")
            props.dispatch(dataLoaded(data))
    }




    return (

        <div>

        </div>
    )
}
const mapStateToProps = function (state) {
    return {
    }
}

export default connect(mapStateToProps)(BlockchainProvider)
