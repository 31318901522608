import React, { useState, useEffect, useRef } from 'react'
import './NavBar.css';
import logo from './logo.png';
import Text from '../../components/Text';
import Heading from '../../components/Heading/Heading';
import button from '../../components/Button/Button';
import Button from '../../components/Button/Button';
import { useWallet } from 'use-wallet'
import { useSelector } from 'react-redux';
import Aos from 'aos'
import 'aos/dist/aos.css'


let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler)
    }
  }, [domNode])
  return domNode
}


const NavBar = (props) => {
  const account = useSelector(state => state.account)
  const [isMobile, setIsMobile] = useState(false);
  const [isActive, setisActive] = useState("/");
  const wallet = useWallet()

  const [button, isButton] = useState(false);

  let domNode = useClickOutside(() => {
    isButton(false)
  })

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    console.log(window.location.pathname, "pathname")
    setisActive(window.location.pathname)
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  }, [isActive])


  useEffect(() => {
    console.log("wallet.connect()", wallet.account)
  }, [wallet])

  useEffect(()=>{
    Aos.init({duration: 1000}) // global duration
  },[isMobile && button])

  return (
    <nav className={navbar ? "navActive" : "nav"} style={{ zIndex: 100000000 }}  >

{/* 
      <table style={{width:"100%"}}>
        <tr >
          <td style={{background:"white" }}>
            <a href="/" className="NLi"><img src={logo} alt="Logo" className="Logo" /></a>
          </td>
          <td style={{ width: "50%", verticalAlign:"middle",background:"red" }}>
            <div className={(isMobile && button) ? "container" : "none"} ref={domNode}>
              <ul className="list" style={{ verticalAlign: "middle" }}>
                {isMobile && <li style={{ color: "white", paddingLeft: "200px" }} onClick={() => setIsMobile(!isMobile)}><div style={{cursor:"pointer"}} Text="X" >X</div></li>}
                <a href="/" className="NLi"><li ><Heading size="2" Text="Home" color={isActive === "/" ? "white" : "#C29AFF"}></Heading></li></a>
                <a href="/openpack" className="NLi"><li ><Heading size="2" Text="Open Pack" color={isActive === "/openpack" ? "white" : "#C29AFF"} ></Heading></li></a>
                <a href="/store" className="NLi"><li ><Heading size="2" Text="Store" color={isActive === "/store" ? "white" : "#C29AFF"} ></Heading></li></a>
                <a href="/polymon" className="NLi"><li ><Heading Text="Polymon" size="2" color={isActive === "/polymon" ? "white" : "#C29AFF"} ></Heading></li></a>
                <a href="#" className="NLi" style={{display:"flex",alignItems:"center"}}>
                    <Button round Text={wallet.status === "connected"|| account ? account ? account.slice(0,10)+"..": wallet.account.slice(0, 10) + "...." : "Connect"}
                      onClick={() => {
                        wallet.connect()
                        // console.log("pontttt")
                      }}></Button>
                </a>
              </ul>
            </div>
            <div className="hamburger" style={{textAlign:"center", marginLeft:"100px"}} onClick={() =>{setIsMobile(!isMobile);isButton(!button); console.log("hii")}} >
              <div className="Line1" onClick={() => isButton(!button)} ></div>
              <div className="Line2" onClick={() => isButton(!button)}></div>
              <div className="Line3" onClick={() => isButton(!button)}></div>
            </div>
          </td>
        </tr>
      </table> */}


      <a href="/" className="NLi"><img src={logo} alt="Logo" className={navbar ?"LogoB" : "Logo"} /></a>
      <div className={(isMobile && button) ? "container" : "none"} ref={domNode} data-Aos="fade-left">
        <ul className="list" >
          {isMobile && <li  style={{ color: "white", paddingLeft: "130px" }} onClick={() => setIsMobile(!isMobile)}><div style={{cursor:"pointer",fontSize:"2rem"}} Text="X" >X</div></li>}
          <a href="/" className="NLi"><li ><Heading Text="Home" size="1.5" color={isActive === "/" ? "#7d2af8" : "#c0c0c0"}></Heading></li></a>
          <a href="/openpack" className="NLi"><li ><Heading Text="Open Pack" size="1.5" color={isActive === "/openpack" ? "#7d2af8" : "#c0c0c0"} ></Heading></li></a>
          <a href="/store" className="NLi"><li ><Heading Text="Store" size="1.5" color={isActive === "/store" ? "#7d2af8" : "#c0c0c0"} ></Heading></li></a>
          <a href="/polymon" className="NLi"><li ><Heading Text="Polymon" size="1.5" color={isActive === "/polymon" ? "#7d2af8" : "#c0c0c0"} ></Heading></li></a>
          <a href="#" className="NLi">
              <Button round Text={wallet.status === "connected" ? wallet.account.slice(0, 10) + "...." : "Connect"}
                onClick={() => {
                  wallet.connect()
                  // console.log("pontttt")
                }}></Button>
          </a>
        </ul>
      </div>
      <div className="hamburger" onClick={() => { setIsMobile(!isMobile); isButton(!button);}} >
        <div className="Line1" onClick={() => isButton(!button)} ></div>
        <div className="Line2" onClick={() => isButton(!button)}></div>
        <div className="Line3" onClick={() => isButton(!button)}></div>
      </div>
    </nav>
  )
}

export default NavBar
