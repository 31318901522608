import React, { useContext } from 'react'
import './Polymon.css'
import spring from './spring.png'
import square from './square.png'
import Heading from '../../components/Heading/Heading'
import Scard from '../../components/Card/Scard'
// import { ItemContext } from '../config/Provider'
import Footer from '../Footer/Footer'
import Post from '../Post/Post'
import { data } from '../config/config'
import PaginationComponent from 'react-reactstrap-pagination'
import UImg from './image10.png';
import styled from 'styled-components'

const Img = styled.div`
    width: 100%;
    height: auto;
    @media only screen and (min-width: 1400px) {
        height: 30rem;
      }
`;

const Polymon = () => {

    // const item = useContext(ItemContext)

    return (
        <section className="mon" id="P">

            {/* <div className="u" >
                <div className="gg"><Heading Text="POLYMON" size={`${window.screen.width > 760 ? "5" : "3"}`} /></div>
            </div> */}
            <Img>
                <img src={UImg} alt="" style={{width:"100%",height:"100%"}} />
            </Img>      

                    <div className="down">
                        {data?.map(e => (
                            <div>
                                <Scard link={e.img} name={e.name} rbtn={e.rbtn} rbg={e.rbg} rank={e.rank} />
                            </div>
                        ))}

                    </div>
{/* 
                    <div style={{ width: "100%", paddingBottom: "50px", textAlign: "center" }}>
                        <PaginationComponent totalItems={100} pageSize={} />
                    </div> */}

                    {/* <Post /> */}
            
            {/* <Footer /> */}
        </section>
    )
}

export default Polymon
