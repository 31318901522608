import React from 'react'
import styled from 'styled-components'
import Text from '../Text';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Image from '../Image/Image';
import HOC from '../HOC/index';

const Scard = (props) => {

    const Box = styled.div`
        height: 21rem;
        width: 18rem;
        background: white;
        padding: 0.5rem;
        border-radius: 8px;
        display:block;
        margin:auto;
    `;
    const Upper = styled.div`
        height: 60%;
        width: 100%;
        // background:linear-gradient(223.06deg, #F6B97B -12.52%, #8648E5 109.87%)
        background: linear-gradient(0deg, rgba(143,82,219,1) 0%, rgba(190,128,177,1) 98%, rgba(191,128,175,1) 100%, rgba(0,0,0,1) 100%); 
        border-radius: 8px;`;
    const Lower = styled.div`
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0.5rem;
        `;

    return (
        <Box>
            <Upper>
                <div style={{width:"10rem",display:"flex",justifyContent:"center",alignItems:"center",marginLeft:"3rem",paddingTop:"1rem"}}>
                <Image link={`${props.link? props.link : ""}`}></Image>
                </div> 
            </Upper>
            <Lower>
                <Text Text={`${props.rank? "#0"+props.rank : "#000"}`} color="#8648E5" ></Text>
                <Heading Text={`${props.name? props.name : "DOT"}`} color="black" size="2"></Heading>
                <div style={{display:"flex"}}>
                <Button round Text={`${props.lbtn? props.lbtn : "LEGENDARY"}`} bg="#E2A58D" height="2rem" width="8rem" ></Button> 
                <div style={{marginLeft:"0.5rem"}}><Button round Text={`${props.rbtn? props.rbtn : "ELECTRIC"}`} bg={`${props.rbg?props.rbg: "#8648E5" }`} height="2rem" width="7rem" ></Button></div>
                </div> 
            </Lower>
           
        </Box>
    )
}

export default  Scard    // HOC( Scard)
