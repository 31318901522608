import React, { useContext } from 'react'
import './Store.css'
import Heading from '../../components/Heading/Heading'
// import spring from './spring.png';
// import cube from './cube.png'
import Ncard from '../../components/Card/Ncard'
// import { ItemContext } from '../config/Provider'
import Footer from '../Footer/Footer'
import {data} from '../config/config'
const Store = () => {

    // const item = useContext(ItemContext)

    return (
        <section className="store" id="S">
            <div className="top">
                <div className={window.screen.width > 760 ? "dx" : "mx"} >
                    <div className="f"><Heading Text="STORE" size={window.screen.width > 760 ? "4" : "3"} /></div>
                    {/* <img src={spring} alt="" style={{position:"absolute",right:"0",top:"0"}} />
                <img src={cube} alt="" style={{position:"absolute",left:"0",bottom:"0"}} /> */}
                </div>
            </div>
            <div className="dwn">
                <div className="GridStyle" style={{maxWidth:`${window.screen.width > 900 ? "70vw" : "100vw"}`, display:"block", margin:"auto"}}>
                    {data?.map(e => (

                        <div style={{ padding: "12px", display: "inline-block", minWidth: "25%" }}>
                            <Ncard link={`images/${e.name.toUpperCase()}.png`} name={e.name.toUpperCase()} style={{ margin: "8px" }} />
                        </div>
                    ))}
                </div>
            </div>
            {/* <Footer /> */}
        </section>
    )
}

export default Store
