import React from 'react'
import './Supply.css'
import Heading from '../../components/Heading/Heading'
// import Text from '../../components/Text/index'
// import all from './all.png'
import circle from './circle.png'
import half from './half.png'
import spring from './spring.png'
import center from './center.png'
import Tokenomics from './tokenomics'

const Supply = () => {
    return (
        <section className="supply">
            <Heading Text="TOKEN SUPPLY : 10.000.000" color="#1E2947" size="2.6"></Heading>
            <Heading Text="PolyMon Tokenomics" color="#8648E5" size="2"></Heading>
            <img src={circle} alt="" style={{ position: "absolute", top: "2rem", left: "3rem" }} className="img" />
            <img src={half} alt="" style={{ position: "absolute", bottom: "2rem", left: "0" }} className="img" />
            <img src={spring} alt="" style={{ position: "absolute", right: "1rem", top: "1rem" }} className="img" />
            <div className="imageDiv" style={{padding:"20px"}}>
                <img src={center} alt="" className="center" style={{width:"100%"}} />
            </div>
            {/* <Tokenomics/> */}
        </section>
    )
}

export default Supply
